import easterBreaker from "easter-break";
import { Center, Heading, Image, Button, Stack, Text } from "@chakra-ui/react";
import logo from "./images/logo.png";

function App() {
  return (
    <Center minH={"100vh"} bgGradient="linear(to-r, #404040, #212121)">
      <Stack alignItems={"center"} direction={"column"} spacing={8}>
        <Stack alignItems={"center"} direction={"column"} spacing={0}>
          <Image src={logo} />
          <Heading bgGradient='linear(to-r, #d3adff, #ff38cd)' bgClip='text' fontSize="4xl" mt={4}>
            Moonpapers
          </Heading>
          <Text color="gray.500" fontSize="lg">
            Сувенирные права лучшего качества
          </Text>
        </Stack>

        <Stack direction={"row"} spacing={3}>
          <Button
            textDecoration={"underline"}
            _hover={{
              color: "#ad109e",
              textDecoration: "none",
              transform: "scale(1.1)",
            }}
            fontSize={"xl"}
            fontWeight="bold"
            onClick={easterBreaker}
          >
            Telegram
          </Button>
        </Stack>
      </Stack>
    </Center>
  );
}

export default App;
