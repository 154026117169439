import { extendTheme } from "@chakra-ui/react";
import "./css/theme.css";

const theme = extendTheme({
	fonts: {
		heading: `'Manrope', sans-serif`,
		body: `'Manrope', sans-serif`,
	},
	styles: {
		global: {
			body: {
				background: `#131313`,
				color: "white",
			},
		},
	},
	config: {
		initialColorMode: "dark",
		useSystemColorMode: false,
		cssVarPrefix: "kirillsaint",
	},
});

export default theme;
